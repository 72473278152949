.ad-layer-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: .3s all;
  transform: translateY(110%);
  background: #fff;
  box-shadow: 0 4px 1rem #3333338c;
  z-index: 999;
  max-width: 800px;
  right: 0;
  padding-bottom: 0.5rem;
}


.ad-layer-bottom.pinned{
  transform: translateY(0);
}

.ad-layer-bottom > .btn.btn-link{
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
}

.ad-layer-bottom > h3{
  color: #fff;
  background: #03A9F4;
  padding: 16px 16px;
  margin: 0 -8px;
}

.ad-layer-bottom img{
  width: 20%;
  height: auto;
  float: right;
  margin-top: 1rem;
  min-width: 156px;
}

.ad-layer-bottom > .call-to-action{
  margin: 0;
  font-size: 26px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 8px;
  font-family: Arial;
}

.ad-layer-bottom > a.btn-primary{
  padding: 6px 20px;
  background: #00a9f4;
  border: 0;
}
