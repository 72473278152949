.is-landing-page .empty{
  background: #fff;
  padding: 1.2rem 1.6rem;
}

.is-landing-page{
  padding: 0 !important;
}

.is-search-page .card .card-image:first-child {
    padding-top: 0;
    min-height: 170px;
    line-height: 0;
}
