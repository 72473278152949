.emu-lp-info-box{
  background: #fff914;
  padding: 12px;
  border-radius: 3px;
  margin: 1rem -4px;
  box-shadow: 0px 2px 5px #ccc;
}

.emu-lp-info-box > h3{
  margin: 0 -12px;
  padding: 0 1rem;
  border-bottom: 2px solid #e5e011;
  color: #a9a513;
  padding-bottom: 6px;
}

.emu-lp-info-box > div.paragraph{
  background: #f9f9f9;
  margin: 0 -12px;
  padding: 12px;
  border-bottom: 2px solid #ddd;
}

.emu-lp-info-box > div.paragraph a {
    color: #f44335;
}
