.is-type-video .wp-block-embed__wrapper {
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin: 0 -3rem;
}

.is-type-video .wp-block-embed__wrapper iframe {
  width: 100%;
}
