.pulse {
  animation: pulse 1s 1s forwards;
}

.jump {
  animation: jump 1s 2s forwards cubic-bezier(.84,-0.54,.31,1.19);
}

.waggle {
  animation: waggle 1s 3s forwards ease-out;
}

.sheen {
  position: relative;
  overflow: hidden;
}

.sheen:after {
  animation: sheen 1s 4s forwards;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.5) 50%, transparent);
  transform: rotateZ(60deg) translate(-1em, 4em);
}

.spin {
  animation: spin 1.5s 5s forwards cubic-bezier(.56,-0.35,.22,1.5);
}

.fade {
  animation: fade 1s 6.5s forwards;
}

@keyframes pulse {
  0% {
    background-color: orange;
  }
  25% {
    background-color: red;
  }
  50% {
    background-color: orange;
  }
  75% {
    background-color: red;
  }
}

@keyframes jump {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(-2em);
  }
}

@keyframes waggle {
  0% {
    transform: none;
  }
  50% {
    transform: rotateZ(-20deg) scale(1.2);
  }
  60% {
    transform: rotateZ(25deg) scale(1.2);
  }
  67.5% {
    transform: rotateZ(-15deg) scale(1.2);
  }
  75% {
    transform: rotateZ(15deg) scale(1.2);
  }
  82.5% {
    transform: rotateZ(-12deg) scale(1.2);
  }
  85% {
    transform: rotateZ(0) scale(1.2);
  }
  100% {
    transform: rotateZ(0) scale(1);
  }
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(0, -4em);
  }
}

@keyframes spin {
  50% {
    transform: rotateZ(-20deg);
    animation-timing-function: ease;
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes fade {
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.start-now, .start-now:after {
  animation-delay: 0s;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
