.scroll {
  white-space: nowrap; /* [1] */
  overflow-x: scroll;; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
  width: 100%;
  position: relative;
  overflow-y: hidden;
}

.scroll > nav > a {
    font-size: 12px;
    padding: 4px;
    text-transform: uppercase;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.fade-away{
  position: absolute;
  height: 2rem;
  width: 2rem;
  right: 0px;
  bottom: 0;
  background: linear-gradient(270deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%);
}
