picture.advgb-image-slider-img img {
    width: 100%;
}

p.advgb-image-slider-text {
    color: #919191 !important;
    top: 0;
    font-size: 0.65rem;
    position: relative;
    padding: 0 10px;
    margin: 0;
    text-align: left;
    width: 100%;
    background: #fff !important;
}

.advgb-image-slider-item-info {
    position: relative !important;
}

.slick-list.draggable {
    height: inherit !important;
    margin: 0 -20px;
}

button.slick-arrow, button.slick-arrow:hover, button.slick-arrow:focus {
    display: block;
    background: #fff !important;
    padding: 3px;
    border-radius: 6px;
}
