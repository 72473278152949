/*
Theme Name: EMUonSpeed
Theme URI: -
Description: Neues Theme der Eimsbütteler Nachrichten auf Performance optimiert.
Version: 0.2.1
Author: Florian Heuer
Author URI: -
Tags: speed, lightweight
*/

//override spectre vars
@import "sass/override_vars.scss";

// Import full Spectre source code
@import "node_modules/spectre.css/src/spectre";
@import "node_modules/spectre.css/src/spectre-exp";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

// Import fonts scss
//@import "sass/material-icons.scss";

// Import EMU specific css
@import "sass/emu-layout.scss";
@import "sass/typo.scss";
@import "sass/emu-header.scss";
@import "sass/emu-menu.scss";
@import "sass/emu-filter.scss";
@import "sass/emu-archive.scss";
@import "sass/emu-intext-teaser.scss";
@import "sass/emu-partner-slider.scss";
@import "sass/img-lazy-load-effect.scss";
@import "sass/card-search.scss";
@import "sass/video.scss";
@import "sass/search-tile.scss";
@import "sass/fading-text.scss";
@import "sass/bottom-ad-banner.scss";

@import "sass/emu-plus.scss";
@import "sass/emu-lp-info-box.scss";
@import "sass/corona-stripe.scss";
@import "sass/advgb-slider-override.scss";
@import "sass/horizontal-scroll-menu.scss";
@import "sass/animations.scss";
@import "sass/heartbeat-animation.scss";
@import "sass/customize-love-like-button.scss";
