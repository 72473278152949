.emu-header{
  position: fixed;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
  height: 64px;
  transition: .3s all;
  border-bottom: 2px solid #ddd;
}

.emu-header.unpinned{
  transform: translateY(-100%);
}

.mobile-logo-section .btn-link{
  top: -15px;
  position: relative;
}

#cn-accept-cookie {
    background: #32b643;
    border-color: #2faa3f;
    color: #fff;
}

.wp-block-image img{
  max-width: 100% !important;
  height: auto !important;
}
