.partner-slider > .columns{
  width: 300%;
  margin: 0;
}

.partner-slider {
  overflow: hidden;
  height: 320px;
  top: 4px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #eee;
}

.partner-slider > .columns > figure{
  /*animation: FadeIn .3s;*/
}

figcaption.blocks-gallery-caption{
  color: #919191;
  font-size: .65rem;
  padding: 0 1rem;
  position: relative;
  top: -10px;
}

figure.wp-block-gallery{
  margin: 0 -1rem;
}
