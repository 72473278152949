img[data-lazy-src] {
	/* we set the opacity to 0 */
	opacity: 0;
}

/*Image without 'data' attribute is (or becomes) visible */
img.lazyloaded {
	/* prepare the future animation */
	-webkit-transition: opacity .5s linear 0.2s;
	   -moz-transition: opacity .5s linear 0.2s;
	        transition: opacity .5s linear 0.2s;

	/* we set the opacity to 1 to do the magic */
	opacity: 1;
}
