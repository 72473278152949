.menu-main{
  transform: translate(100%);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9;
  top: 0;
  height: 100%;
  opacity: 0;
  display: none;
}

.menu-main.opened{
  animation: SlideIn .5s;
  animation-fill-mode: forwards;
  opacity: 1;
  transform: translate(0);
  background: rgba(0, 0, 0, 0.6);
}

.menu-main.closed{
  animation: SlideOut 1s;
  animation-fill-mode: forwards;
  transform: translate(100%);
  background: rgba(0, 0, 0, 0);
}

@keyframes SlideIn {
0% {
  opacity: 0;
  transform: translate(100%);
  background: rgba(0, 0, 0, 0.0);
}

50% {
  opacity: 1;
  transform: translate(20%);
  background: rgba(0, 0, 0, 0.0);
}

75% {
  opacity: 1;
  transform: translate(0);
  background: rgba(0, 0, 0, 0.0);
}

100% {
  opacity: 1;
  transform: translate(0);
  background: rgba(0, 0, 0, 0.6);
}
}

@keyframes SlideOut {
0% {
  opacity: 1;
  transform: translate(0);
  background: rgba(0, 0, 0, 0.6);
}

50% {
  opacity: 1;
  transform: translate(0);
  background: rgba(0, 0, 0, 0.0);
}

75% {
  opacity: 1;
  transform: translate(20%);
  background: rgba(0, 0, 0, 0.0);
}

100% {
  opacity: 1;
  transform: translate(100%);
  background: rgba(0, 0, 0, 0.0);
}
}

.menu-wrapper{
  padding: 2rem !important;
}

.menu-wrapper .emu-logo{
  opacity: 0.2;
}

.menu-wrapper .emu-nav .accordion-header{
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
}

.off-canvas .off-canvas-overlay{
  background: rgba(68, 68, 68, 0.5)
}

.emu-plus-stripe{
  background: #03A9F4;
  color: #fff !important;
  font-weight: bold;
  padding: 6px 48px !important;
  font-size: 20px;
  box-shadow: 0 2px 6px #999;
  border-radius: 0 !important;
  display: block;
  margin: 1rem -2rem 1rem -2rem;
}

.emu-plus-stripe i{
  margin-left: -38px;
  margin-right: 20px;
}

.pill-teaser{
  color: #fff;
  background: #03A9F4;
  display: inline-block;
  padding: 0.6rem;
  height: auto;
}

.main-menu-active:before{
  content: '';
  width:  8px;
  height: 8px;
  position: absolute;
  background: #97d999;
  left: -2px;
  top:  14px;
  border-radius: 50%;
}

 .navbar-section .btn.btn-link{
   position: relative;
   text-transform: uppercase;
 }
