.canvas-menu{
  transition: .3s all;
  transform: translate(100%);
  position: fixed;
  background: #fff;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 5;
  top: 0;
  display: none;
}

.canvas-menu-show{
  transform: translate(0);
}

@keyframes FadeIn {
0% {
  opacity: 0;
  transform: scale(.1);
}

85% {
  opacity: 1;
  transform: scale(1.05);
}
100% {
  transform: scale(1);
  opacity: 1;
}
}

.list-entry, #emu-map {
  animation: FadeIn .3s linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  opacity: 0;
}

$delay : 0.1;
@for $i from 0 through 9 {
  .list-entry{
    &:nth-child(9n+#{$i}){
      animation-delay: #{$delay}s;
    }
  }
  $delay : 0.1 + $delay;
}
