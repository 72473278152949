@import 'override_vars';


.emu-content{
  position: relative;
  padding-top: 64px;
  max-width: 1280px;
  margin: auto;
}

.gutenberg-content{
  width: 100%;
}

#emu-map{
  height: 200px;
}

.t-1{
  top: 0.25rem;
  position: relative;
}

.ad-half-page{
  width: 300px;
  position: sticky;
  position: -webkit-sticky;
  top: 4rem;
  animation:FadeIn .5s forwards;
  opacity: 0;
  animation-delay: 1s;
}

.ad-medium-rectangle{
  background: #ddd;
  height: 250px;
  width: 300px;
}

.ad-billboard{
  width:970px;
  animation:FadeIn .5s forwards;
  opacity: 0;
  animation-delay: 1.5s;
}

.cntnt-nav{
  background: #FFEB3B;
  background: #fff;
}

.cntnt-pri{
  background: #FF5722;
}

.cntnt-sec{
  background: #03A9F4;
}

.card img{
  max-width: 100%;
  height: auto;
}

.wp-block-gallery{
  margin: 0;
}

.algolia-autocomplete{
  width: calc(100% - .8rem) !important;
}

.card em{
  font-style: normal;
  background: $secondary-color;
  padding: 0;
  border-radius: 2px;
}

.footer-symbol{
  font-size: 24px;
  color: #ffbfab;
}

.badge[data-badge]::after {
  font-family: sans-serif;
}

.reading-time{
  font-family: sans-serif;
}

address.profile-address{
  position: relative;
}

.pl-26{
  padding-left: 26px;
  white-space: nowrap;
}

.pl-26 i.fas{
  margin-left: -26px;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

/*p*/

[data-cy~="map-marker"] i.fas{
  font-size: 22px;
  color: #F44336;
}

.divider{
  height: initial;
  margin-top: 3rem !important;
  margin-bottom: 0rem !important;
}

#infinite-footer{
  display: none;
}

.infinite-loader{
  width: 100% !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.infinite-loader .spinner{
  margin: auto !important;
}

.list-entry .card-body p{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 134px;
}

.pl-26 p{
  overflow: visible !important;
}

.column.col-2.is-related .card{
  min-height: 464px;
}

.jan-padding{
  padding: 0 1rem !important;
}

.jan-padding-all-around{
  padding: 1rem !important;
}

.wp-block-cgb-block-advertisement-block{
  margin-bottom: 1.2rem;
}

#searchform{
  transform: translate(0, 4px);
}

.reset-line-clamp .card-body p{
  overflow: initial;
  -webkit-line-clamp: initial;
  max-height: initial;
}

.off-canvas .off-canvas-sidebar{
  left: initial !important;
  right: 0;
  transform: translateX(100%);
}

.card{
  border: 0;
}

.image-full-width{
  max-width: initial !important;
  width: 100% !important;
}

ul{
  list-style-position: outside;
}

.chip{
  white-space: normal !important;
  text-overflow: initial !important;
}

.badge[data-badge]::after,
.badge:not([data-badge])::after {
    background: #f44436;
}

.btn.btn-alternate {
    background: #a2dda4;
    border-color: #9bc19d;
    color: #fff;
    margin: 1rem 0;
    max-width: 380px;
}

.btn.btn-alternate:hover {
  background: #9bc19d;
}

.btn.btn-alert {
    background: #F44336;
    border-color: #ce483e;
    color: #fff;
    margin: 1rem 0;
    width: 100%;
    max-width: 380px;
}

.btn.btn-alert:hover {
  background:   #f75d52;;
  border-color: #e0544a;
}

.gads-medium-rectangle{
  width: 300px;
  display: inline-block;
  float: left;
  margin: 0 1rem 1rem 0;
  max-width: 380px;
}

.uwp-login, .uwp-registration, .uwp-forgot, .uwp-account {
  max-width: inherit !important;
}

picture.img-responsive img {
  max-width: 100%;
  height: auto;
  display: block;
}

.save-eimsbush{
  font-size:10px;
  color:#555;
  background: #fef914;
  top: -4px;
  position: relative;
  text-transform: uppercase;
  padding: 10px;
}

@media (max-width: 480px) {

  .m-hide-xs{
    margin: 0 !important;
  }

}
