.emu-plus-featured-articles{
  overflow-x: scroll;
  padding: 0 8px;
  margin-bottom: -1.2rem;
  scroll-behavior: smooth;
  /*-ms-overflow-style: none;
  scrollbar-width: none;*/
}

.emu-plus-featured-articles::-webkit-scrollbar{
  /* display: none; */
  background: transparent;
}

.emu-plus-featured-articles .h5{
  min-height: 3rem;
}

.emu-plus-featured-articles > .scroller{
  width: 280%;
  padding: 1rem 0;
}

.emu-plus-btn{
  background: #00a9f4;
  color: #fff;
  border: none;
  margin-top: 1rem;
}

.emu-plus-btn:visited{
  color: #fff;
}


.emu-plus-drop-shadow{
  box-shadow: 0 4px 4px #ddd;
}

.emu-plus-card .card-subtitle{
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emu-plus-card .card{
  min-height: 22rem;
  transition: .3s all;
}

.emu-plus-card .card-header a{
  text-decoration: none;
}

.emu-plus-card .card:hover{
  box-shadow: 0 8px 12px #ccc;
  margin-top: -6px;
}

.emu-plus-last .card-title.h5{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.emu-plus-last .card{
  max-height: none;
  min-height: auto;
  margin-top: 0rem;
  height: 19.3rem;
}

.scroll-btn-emu-plus{
  padding: 10px 15px;
  background: #eee;
  display: inline-block;
  line-height: 0;
  border-radius: 100%;
  box-shadow: 0 2px 4px #00000070;
  top: 50%;
  position: absolute;
  border: 0;
  cursor: pointer;
  transition: .3s all;
  outline: 0;
}

.scroll-btn-emu-plus.left{
    left: 0px;
}


.scroll-btn-emu-plus.left:hover{
  margin-left: -6px;
}

.scroll-btn-emu-plus.right:hover{
  margin-right: -6px;
}

.scroll-btn-emu-plus.right{
    right: 0px;
}

@media only screen and (max-width: 480px) {
  .emu-plus-featured-articles > .scroller{
    width: 540%;
  }

  .emu-plus-last .card{
    min-height: 17rem;
  }
}
