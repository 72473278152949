.text-large {
  font-size: 1.1em !important;
}

.h1, h1{
  font-size: 1.4rem;
  font-weight: 700;
}

.h2, h2{
  font-size: 1.1rem;
  font-weight: 700;
}

.h3, h3{
  font-size: 1.1rem;
  font-weight: 700;
}

.h4, h4{
  font-size: 1.0rem;
  font-weight: 700;
}

.h5, h5{
  font-size: 1.0rem;
  font-weight: 700;
}

.h6, h6{
  font-size: 1.0rem;
  font-weight: 700;
}

.roof-line{
  margin: 0.5rem 0;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 700;
}

.image-caption{
  font-size: 0.65rem;
}

.meta-line{
  font-family: sans-serif;
  font-size: 0.7rem;
  color: #919191;
  margin-top: 0.5rem;
}

.author-line{
  margin: 0.5rem 0;
  display: inline-block;
}

.main-article a {
    color: #73bb75;
}

ul#autocomplete-list a {
    font-size: 0.7rem;
}

/* images in article */
figure.wp-block-image figcaption {
    color: #919191;
    top: 0;
    font-size: 0.65rem;
    padding: 0 1rem;
    position: relative;
}

figure.wp-block-image {
    padding: 0;
    margin: 0 -1rem;
    max-width: inherit;
}
