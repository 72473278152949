.fade-text-out{
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(255,255,255,1) 8%, rgba(255,255,255,0) 75%);
}

.paywall{
  height: 28rem;
  overflow: hidden;
  position: relative;
}
