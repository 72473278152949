.intext-teaser{
  background: rgba(51, 51, 51, 0.6) !important;
  color: #fff;
  overflow: hidden;
  position: relative;
  margin-bottom: 1.2rem;
}

.intext-teaser > img{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
