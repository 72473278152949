/**
*
*/

.overview-tile-wrapper{
  position: relative;
  padding-top: 66.6%;
}

.overview-tile {
  border: 1px solid #ccc;
  height: 80%;
  position: absolute;
  width: 80%;
  background: #fff;
  right: 0;
  top: 0%;
}

.overview-tile-wrapper img{
  bottom: 0;
  width: 80%;
  position: absolute;
}

.overview-tile {
  border: 1px solid #ccc;
  height: 80%;
  position: absolute;
  width: 80%;
  background: #fff;
  right: 0;
  top: 0;
  border-radius: 2px;
}

.overview-tile:nth-of-type(2) {
  top: 6%;
  right: 7%;
}

.overview-tile:nth-of-type(3) {
  top: 12%;
  right: 14%;
}
