.btn-love-like{
  min-width: 64px;
}

.btn-love-like .love{
  margin: 0 !important;
  display: inline-block !important;
}

.love-like-info-view{
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  opacity: .8;
}

.love-like-info-count{
  font-size: 12px;
  font-family: sans-serif;
  text-align: center;
  position: relative;
  top: -4px;
}
