//global font font settings
$base-font-family: 'Rosario', sans-serif;
$primary-color: #333; //#009688; //#82be3d; //#cddc39; // alternatives: #7cbf47;
$secondary-color: #a2dda4;// #ff6a5f;
$dark-color: #444;
$gray-color: #ddd;
$bg-color: #eee;
$gray-light-color: #f3f3f3;
$border-color-light: #f3f3f3;
$font-size: 0.9rem !default;
$font-size-sm: 0.8rem !default;
$font-size-lg: 1rem !default;
$fa-font-display: swap !default;
$fa-font-path: "webfonts" !default;
