.corona-stripe{
  background: #FFF914;
  display: inline-block;
  padding: 4px 8px;
  white-space: nowrap;
}

#search-box-wrapper{
  padding: 8px;
}
